
$primary : #B89965;
$secondary : #0C3B5E;
$enable-responsive-font-sizes: true;
$border-radius: 0.875rem;
$box-shadow: 0 0 0.7rem rgba(black, .15);

// Function for converting a px based font-size to rem.
@mixin px-to-rem($pxValue, $property: "font-size", $standard-size: 16) {
  #{$property}: $pxValue + px;
  #{$property}: calc($pxValue/$standard-size) + rem;
}

@import "~rfs/scss";

@import "~bootstrap/scss/bootstrap";

body
{
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}

.montserrat, .montserrat *
{
  font-family: 'Montserrat', sans-serif;
}

.font-weight-semibold
{
  font-weight: 600;
}

.blocksupports
{
  h6
  {
    @include media-breakpoint-down(md)
    {
      @include px-to-rem(11,'font-size');
      @include px-to-rem(15,'line-height');
    }
  }
}

h2
{
    color: $secondary;
    text-align: center;
    font-size: 2rem;
    line-height: 2.4rem;
    font-family: 'Montserrat', sans-serif;
}

h3
{
  font-size: 32px;
  font-weight: bold;
  
}

.btn
{
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  &-2
  {
    padding: 0 10px;
  }
}
.text-secondary a
{
  color: $secondary;
  &:hover
  {
    color: $secondary;
  }
}
a
{
  color: white;
  &:hover
  {
    color: $primary;
  }
}

.footer
{
  &-sub
  {
    font-size: 18px;
    line-height: 16px;
    position: relative;
  }
  &-text
  {
    font-size: 15px;
    line-height: 19px;
  }
}

.logo-head-mobile
{
  @include px-to-rem(11,'font-size');
  @include px-to-rem(14,'line-height');
}

.text-resp-small
{
  @include px-to-rem(11,'font-size');
  @include px-to-rem(14,'line-height');
  @include media-breakpoint-up(sm)
  {
    @include px-to-rem(12,'font-size');
    @include px-to-rem(16,'line-height');
  }
}

.title-custom
{
  @include px-to-rem(12,'font-size');
  @include px-to-rem(14,'line-height');
  @include media-breakpoint-up(md)
  {
    @include px-to-rem(32,'font-size');
    @include px-to-rem(40,'line-height');
  }
  @include media-breakpoint-up(lg)
  {
    @include px-to-rem(58,'font-size');
    @include px-to-rem(67,'line-height');
  }
}
.legal-header
{
  padding-bottom: 60px !important;

  @include media-breakpoint-up(lg)
  {
    padding-bottom: 120px !important;
  }
}

.legal
{
  @include px-to-rem(40,'font-size');
  @include px-to-rem(48,'line-height');
  @include media-breakpoint-up(lg)
  {
    @include px-to-rem(58,'font-size');
    @include px-to-rem(68,'line-height');
  }
}
.sub-title-custom 
{
  @include px-to-rem(16,'font-size');
  @include px-to-rem(18,'line-height');
  @include media-breakpoint-up(md)
  {
    @include px-to-rem(40,'font-size');
    @include px-to-rem(46,'line-height');
  }
  @include media-breakpoint-up(lg)
  {
    @include px-to-rem(76,'font-size');
    @include px-to-rem(88,'line-height');
  }
}

.after-black
{
  filter: drop-shadow(0 0 100px #000);
}

.copyright
{
  font-size: 12px;
}
input {
  outline: none;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 100%;
  font-size: 14px;
  font-family: inherit;
}

.bg-light-blue
{
  &>div
  {
    z-index: 100;
  }
  &::after
  {
    z-index: 1;
    left: 0;
    margin-top: 15px;
    content: "";
    background-color: #275B81;
    position: absolute;
    width: 100vw;
    height: 75px;
    @include media-breakpoint-up(lg)
    {
      display: none;
    }
  }
}

.bg-header-parmentier
{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: $secondary;
  background-image: url("../../public/images/Header-mobile-bg.png");
  @include media-breakpoint-up(sm)
  {
    background-image: url("../../public/images/Header-bg.png");
  }
  @include media-breakpoint-up(xl)
  {
    background-image: url("../../public/images/Header-bg.png");
  }
}
.bg-footer-parmentier
{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../public/images/Footer-1.png");
  @include media-breakpoint-up(md)
  {
    background-image: url("../../public/images/Footer-2.png");
  }
}

// GENERAL

.bg-light-grey
{
  background: #F6F6F7;
}
.bg-beige
{
  background: linear-gradient(0.25turn,#E5DAC7, #D6C4A6);
}

.p-35
{
  padding: 0.825rem;
  &.block-metiers
  {
    @include media-breakpoint-down(md)
    {
      padding: 0;
    }
  }
}
div
{
  @include media-breakpoint-down(md)
  {
    &.pr-resp-1
    {
      padding-right: 1px !important;
    }
    &.pl-resp-1
    {
      padding-left: 1px !important;
    }
    &.pb-resp-1
    {
      padding-bottom: 2px !important;
    }
  }
}


// METIERS

.metiers-hover-container
{
  height: 345px;
  @include media-breakpoint-down(lg)
  {
    height: 180px;
    padding: 0;
    &>img
    {
      transform: scale(0.5);
    }
  }
  &:hover
  {
    .metiers-image
    {
      transform: scale(1.15);
    }
    .metiers-nohover
    {
      display: none;
    }
    .metiers-hover
    {
      display: block !important;
    }
    div.metiers-filter
    {
      top: -250px;
      opacity: 0 !important;
      &-hover
      {
        opacity: 0.79 !important;
      }
    }
  }
  div.metiers-filter
    {
      top: 0;
      opacity: 0.79;
      transition: all 0.75s;
      background-color: #002D4B;
      &-hover
      {
        opacity: 0 !important;
        background: radial-gradient(#B89965,transparent 50%);
        transition: all 0.75s;
      }
    }
    .metiers-image
    {
      transition: all 0.75s;
    }
}

// ECORESP

.logo-green p
{
  @include px-to-rem(12,'font-size');
  @include px-to-rem(14,'line-height');
  @include media-breakpoint-up(lg)
  {
    @include px-to-rem(18,'font-size');
    @include px-to-rem(22,'line-height');
  }
}

.ecomobile-img
{
  width: 160px !important;
  @include media-breakpoint-up(lg)
  {
    width: 240px !important;
  }
}

p.text100-green
{
  color: #00DE42;
  .t1
  {
    @include px-to-rem(18,'font-size');
    @include px-to-rem(33,'line-height');
  }
  .t2
  {
    @include px-to-rem(22,'font-size');
    @include px-to-rem(28,'line-height');
  }
  .up
  {
    @include px-to-rem(43,'font-size');
    @include px-to-rem(28,'line-height');
  }
  @include media-breakpoint-up(lg)
  {
    .t1
    {
      @include px-to-rem(33,'font-size');
      @include px-to-rem(33,'line-height');
    }
    .t2
    {
      @include px-to-rem(37,'font-size');
      @include px-to-rem(37,'line-height');
    }
    .up
    {
      @include px-to-rem(133,'font-size');
      @include px-to-rem(133,'line-height');
    }
  }
}

// FOOTER

.custom-sep
{
  height: 26px;
  display: inline-block;
  border-right: 1px solid #055581;
  vertical-align: middle;
  margin: 0 12px;
}